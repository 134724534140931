<template>
  <list-select
      ref="select"
      async
      :emit-only-value="false"
      v-on:input="(v) => $emit('input', v)"
      :search-data="search"
      :parse-callback="parse"
      v-model="model"
      :autofocus="autofocus"
      :placeholder="placeholder"
  />
</template>

<script>
import ListSelect from "@/reuse/input/ListSelect"
export default {
  name: "StatusNegocioSelect",
  props: {
    value: {
      required: true
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    isGerencia: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      model: this.value,
      result: []
    }
  },
  watch: {
    value (v) {
      this.$nextTick(() => {
        if (v !== this.model) {
          this.$refs.select.autoSelectValue(v)
        }
      })
    }
  },
  components: {ListSelect},
  methods: {
    search (terms) {
      console.log('Find', this.model)
      const filtros = []
      if (terms) {
        filtros.push('search=' + terms)
      }
      return new Promise((resolve, reject) => {

        let list = this.result = [
          {label: 'Somente ativos', value: 'ativos'},
          {label: 'Somente fechados', value: 'fechados'},
          {label: 'Todos', value: null},
          {label: 'Ganhos', value: 'ganhos'},
          {label: 'Perdidos', value: 'perdidos'},
        ]
        resolve(list)
      })
    },
    parse (v) {
      if (v && v.label) {
        return v.label
      }
      return v
    }
  }
}
</script>
