import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 2
export const listName = 'crm.lista'
export const listStorage = tableColumnsCache(listName, [
    {label: 'Título', name: 'titulo', active: true, sortable: true, ordering: 0},
    {label: 'ID', name: 'id', active: true, sortable: true, ordering: 2},
    {label: 'Pessoa', name: 'pessoa', active: true, sortable: true, ordering: 2},
    {label: 'Empresa', name: 'organizacao', active: true, sortable: true, ordering: 2},
    {label: 'Previsão', name: 'dataFechamentoEsperada', active: true, sortable: true, ordering: 2},
    {label: 'Funil', name: 'funil', active: true, sortable: true, ordering: 2},
    {label: 'Etapa', name: 'funilEtapa', active: true, sortable: true, ordering: 2},
    {label: 'Situação', name: 'situacao', active: true, sortable: true, ordering: 2},
    {label: 'Fechado', name: 'fechado', active: true, sortable: true, ordering: 2},
], listVersion)
