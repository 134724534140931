export default function (funil, id = null, newByEtapa) {
  let window = id ? `negocio.${id}` : 'negocio.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: !!id ? 'Criar Negócio' : 'Atualizar Negócio',
    width: '800',
    height: '600',
    minHeight: '430px',
    backdrop: false,
    move: true,
    clickOutside: true,
    windowClass: 'erp-window-modern v2',
    contentClass: '',
    props: {
      router: this.$router,
      processo: this.processo,
      funil,
      newByEtapa,
      id
    }
  }, () => import('../../negocio/components/window/NovoNegocio'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        created: (wid, data) => {
          console.log('Negocio created event')
          this.created && this.created(data)
          this.load()
        },
        updated: (wid, data) => {
          console.log('Negocio updated event')
          this.updated && this.updated(data)
          this.loadContainerNegocio()
        }
      })
    }) // return wid
}
